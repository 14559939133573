<template>
  <div>
    <portal to="body-top">
      <h3 class="text-center font-weight-bolder mb-1">
        <b-skeleton
          height="30px"
          style="border-radius: 5px"
        />
      </h3>
    </portal>

    <quote-status-bar-skeleton />
    <div class="mt-1">
      <div class="bg-white px-1 rounded-lg">
        <h2 class="font-weight-bolder font-medium-5 pt-2">
          {{ $t('Dispatch') }}
        </h2>
        <div>
          <b-row>
            <b-col md="4">
              <b-col
                class="d-flex align-items-center justify-content-between mb-1"
              >
                <span class="w-45 font-weight-bolder">
                  {{ $t('Date') }}
                </span>
                <span class="w-55 font-weight-bolder">
                  <b-skeleton
                    height="30px"
                    width="240px"
                  />
                </span>
              </b-col>
              <b-col
                class="d-flex align-items-center justify-content-between mb-1"
              >
                <span class="w-50 font-weight-bolder ">
                  {{ $t('Method') }}
                </span>
                <span class="w-50 ">
                  <b-skeleton
                    height="30px"
                    style="border-radius: 5px"
                  />
                </span>
              </b-col>
              <b-col
                class="d-flex align-items-center justify-content-between mb-1"
              >
                <span class="w-50 font-weight-bolder">
                  {{ $t('Dispatch Location') }}
                </span>
                <span class="w-50 font-weight-bolder">
                  <b-skeleton
                    height="30px"
                    style="border-radius: 5px"
                  />
                </span>
              </b-col>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div class="mt-1">
      <div class="bg-white px-1 rounded-lg">
        <h2 class="font-weight-bolder font-medium-5 pt-2">
          {{ $t('Transfer Items') }}
        </h2>
        <b-skeleton
          height="30px"
          style="border-radius: 5px"
        />
        <h2 class="font-weight-bolder font-medium-5 pt-2">
          {{ $t('Order RO-') }}
        </h2>
        <b-skeleton
          height="60px"
          style="border-radius: 5px"
        />
        <h2 class="font-weight-bolder font-medium-5 pt-2">
          {{ $t('Permanent Transfer') }}
        </h2>
        <b-skeleton
          height="60px"
          style="border-radius: 5px"
        />
      </div>
    </div>

  </div>
</template>

<script>
import {
  BCol, BFormCheckbox, BRow, BSkeleton,
} from 'bootstrap-vue'
import QuoteStatusBarSkeleton from '@/views/main/orders/components/use-as-global/QuoteStatusBarSkeleton.vue'
import LTableListCollector from '@/views/components/LTableListCollector.vue'

export default {
  name: 'Skeleton',
  components: {
    BFormCheckbox, LTableListCollector, QuoteStatusBarSkeleton, BRow, BCol, BSkeleton,
  },
}
</script>

<style lang="scss" scoped>

</style>
