<template>
  <div class="bg-white px-1 rounded-lg">
    <h2 class="font-weight-bolder font-medium-5 pt-2">
      {{ $t('Order Items') }}
    </h2>
    <span>
      <scan
        :asset-item-serial-numbers="assetItemSerialNumbers"
        @getValue="handleCheckBySerialNumber"
      />
      <l-table-list-collector
        ref="lTableRef"
        :table-columns="tableColumns"
        :module-name="MODULE_NAME"
        :fetched-data="assetsItem"
        :is-searchable.camel="false"
        :row-active="{
          filedKey: 'pick_at',
          classes: 'highlightRow'
        }"
      >
        <template #head(action)>
          <div
            class="d-flex p-0"
          >
            <b-form-checkbox
              :indeterminate="isIndeterminate && !pickedAllItems"
              :checked="pickedAllItems"
              :disabled="allItemsPacked"
              @change="handleCheckAllItems"
            />
          </div>
        </template>
        <template #cell(action)="{ data: { item }}">
          <b-form-checkbox
            :checked="item.picked"
            :disabled="!!item.pick_at"
            @change="handleCheckItem($event, item.id)"
          />
        </template>
        <template #cell(state)="{ data: { item }}">
          <div
            class="d-flex pl-1"
          >
            <state-of-asset
              :data="item"
              :with-label="false"
            />
          </div>
        </template>
        <template #cell(sku)="{ data: { item }}">
          {{ getValueFromGivenObjectByKey(item, 'product.sku', '—') }}
        </template>
        <template #cell(name)="{ data: { item }}">
          {{ getValueFromGivenObjectByKey(item, 'product.name', '—') }}
        </template>
        <template #cell(asset_id)="{ data: { item }}">
          <sub-rental-assets :item="item" />
        </template>
        <template #cell(picked_status)="{ data: { item }}">
          <div class="text-center">
            <feather-icon
              v-if="item.pick_at"
              icon="LApproved"
              size="20"
            />
          </div>
        </template>
      </l-table-list-collector>
    </span>
  </div>
</template>

<script>
import config from '@/views/main/warehouse/view/pick/service-order/serviceOrderConfig'
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import { BFormCheckbox } from 'bootstrap-vue'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import StateOfAsset from '@/views/main/inventory/inventory-view/components/StateOfAsset.vue'
import { assetStates } from '@/enum/asset-statuses'
import Scan from '@/views/components/Scan/Scan.vue'
import { map, find } from 'lodash'
import SubRentalAssets from '@/views/main/warehouse/main-order/components/sub-rental-asset/SubRentalAsset.vue'

export default {
  name: 'ServiceOrderItems',
  components: {
    Scan, StateOfAsset, BFormCheckbox, LTableListCollector, SubRentalAssets,
  },
  computed: {
    isIndeterminate() {
      return this.assetsItem.some(item => item.picked)
    },
    pickedAllItems() {
      return this.assetsItem.every(item => item.picked)
    },
    assetsItem() {
      return this.$store.state[this.MODULE_NAME].assetsItem
    },
    assetItemSerialNumbers() {
      return map(this.assetsItem, item => `${item.inventory_item.serial_number}_${item.product.sku}`)
    },
    getServiceInformation() {
      return this.$store.state[this.MODULE_NAME].getServiceInformation
    },
    allItemsPacked() {
      return this.assetsItem.every(item => item.pick_at)
    },
  },
  methods: {
    handleCheckAllItems(checked) {
      const checkedItems = map(this.assetsItem, item => ({ ...item, picked: checked }))
      this.$store.commit(`${this.MODULE_NAME}/SET_ASSETS_ITEM`, checkedItems)
    },
    handleCheckItem(checked, id) {
      const checkedItems = map(this.assetsItem, item => (item.id === id ? { ...item, picked: checked } : item))
      this.$store.commit(`${this.MODULE_NAME}/SET_ASSETS_ITEM`, checkedItems)
    },
    handleCheckBySerialNumber(serialNumber) {
      const val = serialNumber.trim()
      const checkedItems = find(this.assetsItem, item => ((item.inventory_item && item.inventory_item.serial_number === val) || item.product.sku === val))
      if (!checkedItems) return
      this.handleCheckItem(!checkedItems.picked, checkedItems.id)
    },
  },
  setup() {
    const { MODULE_NAME, tableColumns } = config()
    return {
      MODULE_NAME,
      tableColumns,
      getValueFromGivenObjectByKey,
      assetStates,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
