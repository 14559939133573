<template>
  <div class="d-flex mt-2 pb-1 justify-content-between">
    <b-button
      class="cancelBtn font-medium-1 font-weight-bolder"
      variant="outline-primary"
      @click="$router.push({ name: 'home-warehouse-view' })"
    >
      {{ $t('Back to List') }}
    </b-button>
    <div class="d-flex">
      <b-button
        variant="secondary"
        class="font-medium-1 font-weight-bold px-3 d-flex align-items-center justify-content-center ml-2"
        @click="printContent()"
      >
        <feather-icon
          icon="LPrintIcon"
          style="margin-right: 10px"
          size="20"
        />
        <span>{{ $t('Print Pick List') }}</span>
      </b-button>

      <b-button
        v-if="assetsItemPicked"
        variant="success"
        class="d-flex  justify-content-center ml-2"
        style="width: 170px; height: 40px;"
        @click="handleSubmit(PACKED_ACTION_STATE)"
      >
        <feather-icon
          icon="LBoxIcon"
          style="margin-right: 10px"
          size="20"
        />
        <span>{{ $t(PACKED_ACTION_STATE) }}</span>
      </b-button>
      <b-button
        v-else
        variant="success"
        class="d-flex  justify-content-center ml-2"
        style="width: 170px; height: 40px;"
        :disabled="!someAssetsPicked || isLoading "
        @click="handleSubmit(PICKED_ACTION_STATE)"
      >
        <feather-icon
          icon="LPickIcon"
          style="margin-right: 10px"
          size="20"
        />
        <span>{{ $t(PICKED_ACTION_STATE) }}</span>
      </b-button>
    </div>
  </div>
</template>

<script>

import { BButton } from 'bootstrap-vue'
import { printContent } from '@/views/main/orders/components/helpers'
import config from '@/views/main/warehouse/view/pick/service-order/serviceOrderConfig'
import { assetStates } from '@/enum/asset-statuses'
import mainConfig from '@/views/main/warehouse/main-order/config'

export default {
  name: 'ServiceOrderFormBottom',
  components: { BButton },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    assetStates() {
      return assetStates
    },
    getServiceInformation() {
      return this.$store.state[this.MODULE_NAME].getServiceInformation
    },
    assetsItem() {
      return this.$store.state[this.MODULE_NAME].assetsItem
    },
    someAssetsPicked() {
      return this.assetsItem.some(item => item.picked && !item.pick_at)
    },
    assetsItemPicked() {
      return this.assetsItem.every(item => item.pick_at)
    },
  },
  methods: {
    handleSubmit(action) {
      const orderId = this.getServiceInformation.id
      if (action === this.PACKED_ACTION_STATE) {
        this.redirect(action, orderId)
      } else {
        const payload = {
          order_product_ids: this.assetsItem.filter(item => item.picked && !item.pick_at).map(item => item.id),
          warehouse_notes: this.getServiceInformation.warehouse_notes,
          order_id: orderId,
        }
        this.isLoading = true
        this.$store.dispatch(`${this.MODULE_NAME}/pickUpServiceOrder`, payload)
          .then(() => {
            this.$store.commit(`${this.MODULE_NAME}/SET_SERVICE_INFORMATION`, {})
            this.$store.commit(`${this.MODULE_NAME}/SET_ASSETS_ITEM`, [])
            this.$store.commit(`${this.MODULE_NAME}/SET_RE_RENDER_COMPONENT_KEY`)
            this.redirect(this.PACKED_ACTION_STATE, orderId)
          }).catch(err => {
            this.errorNotification(this, err)
          }).finally(() => {
            this.isLoading = false
          })
      }
    },
    redirect(action, id) {
      if (action === this.PACKED_ACTION_STATE) {
        this.$router.push({ name: 'home-warehouse-pack-order', params: { id, orderType: this.ORDER_TYPES_KV.SM } })
      }
    },
  },
  setup() {
    const {
      MODULE_NAME, PICKED_ACTION_STATE,
      PACKED_ACTION_STATE,
    } = config()

    const { ORDER_TYPES_KV } = mainConfig()

    return {
      printContent,
      MODULE_NAME,
      PICKED_ACTION_STATE,
      PACKED_ACTION_STATE,
      ORDER_TYPES_KV,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
