var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white px-1 rounded-lg"},[_c('h2',{staticClass:"font-weight-bolder font-medium-5 pt-2"},[_vm._v(" "+_vm._s(_vm.$t('Order Items'))+" ")]),_c('span',[_c('scan',{attrs:{"asset-item-serial-numbers":_vm.assetItemSerialNumbers},on:{"getValue":_vm.handleCheckBySerialNumber}}),_c('l-table-list-collector',{ref:"lTableRef",attrs:{"table-columns":_vm.tableColumns,"module-name":_vm.MODULE_NAME,"fetched-data":_vm.assetsItem,"isSearchable":false,"row-active":{
        filedKey: 'pick_at',
        classes: 'highlightRow'
      }},scopedSlots:_vm._u([{key:"head(action)",fn:function(){return [_c('div',{staticClass:"d-flex p-0"},[_c('b-form-checkbox',{attrs:{"indeterminate":_vm.isIndeterminate && !_vm.pickedAllItems,"checked":_vm.pickedAllItems,"disabled":_vm.allItemsPacked},on:{"change":_vm.handleCheckAllItems}})],1)]},proxy:true},{key:"cell(action)",fn:function(ref){
      var item = ref.data.item;
return [_c('b-form-checkbox',{attrs:{"checked":item.picked,"disabled":!!item.pick_at},on:{"change":function($event){return _vm.handleCheckItem($event, item.id)}}})]}},{key:"cell(state)",fn:function(ref){
      var item = ref.data.item;
return [_c('div',{staticClass:"d-flex pl-1"},[_c('state-of-asset',{attrs:{"data":item,"with-label":false}})],1)]}},{key:"cell(sku)",fn:function(ref){
      var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'product.sku', '—'))+" ")]}},{key:"cell(name)",fn:function(ref){
      var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'product.name', '—'))+" ")]}},{key:"cell(asset_id)",fn:function(ref){
      var item = ref.data.item;
return [_c('sub-rental-assets',{attrs:{"item":item}})]}},{key:"cell(picked_status)",fn:function(ref){
      var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[(item.pick_at)?_c('feather-icon',{attrs:{"icon":"LApproved","size":"20"}}):_vm._e()],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }